/* You can add global styles to this file, and also import other style files */
@use "@angular/material" as mat;

@import "./variables";

*,
*::before,
*::after {
    box-sizing: border-box;
}

html,
body {
    height: 100%;
}

body {
    margin: 0;
    font-family: aktiv-grotesk-extended, sans-serif;
}

.mat-mdc-snack-bar-container {
    &.snackbar {
        --mdc-snackbar-container-color: #34383b;
        --mat-mdc-snack-bar-button-color: #34383b;
        --mdc-snackbar-supporting-text-color: #fff;
    }
    margin-bottom: 70px !important;
}

.mat-mdc-snack-bar-container .mat-mdc-button.mat-mdc-snack-bar-action:not(:disabled) {
    color: violet !important;
    --mat-mdc-button-persistent-ripple-color: currentColor;
}

.mdc-switch:enabled .mdc-switch__track::after,
.mdc-switch .mdc-switch__handle::after {
    background: $grey !important;
}

.mdc-switch .mdc-switch__handle::after {
    background: $red !important; /* Red for the handle */
}

button.save-btn {
    color: $charcoal-blue !important;
    background: $golden-yellow !important;
    font-size: 15px !important;
    height: 32.5px !important;
}

button.help-btn {
    color: $white !important;
    font-size: 15px !important;
    height: 32.5px !important;

    &:enabled {
        background: $charcoal-blue !important;
    }

    &:disabled {
        background: $grey !important;
    }
}

button.save-btn[disabled] {
    background-color: $grey !important;
    color: $white !important;
}

button.cancel-btn {
    color: $white !important;
    background: $warm-orange !important;
    font-size: 15px !important;
    height: 32.5px !important;
}

button.cancel-btn[disabled] {
    background-color: $grey !important;
    color: $white !important;
}

.mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background,
.mdc-checkbox .mdc-checkbox__native-control[data-indeterminate="true"]:enabled ~ .mdc-checkbox__background {
    border-color: $red !important;
    background-color: $red !important;
}

.dense-1 {
    @include mat.all-component-densities(-1);
}

.dense-2 {
    @include mat.all-component-densities(-2);
}

.dense-3 {
    @include mat.all-component-densities(-3);
}

@media (max-width: 345px) {
    body.mat-typography {
        width: fit-content;
    }
}

label.star {
    padding: 1px !important;
    font-size: 28px !important;
}

.timepicker-overlay[_ngcontent-ng-c2055447140] {
    z-index: 1000 !important;
    width: 100% !important;
}

.min-purchase-message {
    font-size: 13px;
    color: $orange;
}

.mat-mdc-progress-spinner .mdc-circular-progress__determinate-circle,
.mat-mdc-progress-spinner .mdc-circular-progress__indeterminate-circle-graphic {
    stroke: $yellow !important;
}

.text-red {
    color: $red;
    font-size: 12px;
}

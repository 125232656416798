// Font color
$white: #ffffff;
$white-opacity80: #ffffffcc;
$black: #121212;
$black-zero-opacity: #00000000;
$light-gray-border: #d4d5d9;
$silver: #ccc;
$light-black: #121212bf;
$light-grey: #f0f0f0;
$grey: #a9a9a9;
$dark-grey: #666;
$medium-light-grey: #aaa;
$blue: #007bff;
$dark-blue: #0055af;
$alice-blue: #f4f8ff;
$yellow: #fdda0d;
$purple: #9370db;
$red: #ff0000;
$light-blue: #add8e6;
$orange: #ffa500;
$green: #0dce0d;
$dark-green: #0c650c;
$black-with-opacity: #0000001f; // opacity = 0.12
$mid-gray: #a9abb2;
$white-smoke: #f9f9f9;
$pink: #ffc0cb;
$dark-charcoal: #333;
$dark-charcoal-opacity20: #33333333;
$light-sea-green: #139b94;
$transparency-with-opacity: #00000080;
$dark-charcoal-opactity: rgb(51, 51, 51, 0.25);
$hover-color: #eeeeee;
$light-silver: #f8f9fb;
$charcoal-blue: #3f5374;
$warm-orange: #e86231;
$light-silver: #f8f9fb;
$golden-yellow: #f4c753;
$golden-yellow-light: #fffef0;
$light-blue: #5599d5;
$light-pink: #f8f2f2;
$periwinkle: #e4e9f1; //A light, slightly muted blue with a hint of lavender
$designer-dark-green: #117a65;
$designer-green: #21ba45;
$designer-sky-blue: #5dade2;
$designer-violet: #3633bf;
$designer-orange: #cf871b;
$designer-teal: #00b5ad;
$designer-light-blue: #2185d0;
$designer-dark-blue: #3c79b7;
$semi-transparent-black: #00000033;
$soft-peach: #fdf1ed;
$bright-blue: #1a73e8;
$silver-gray: #e0e0e0;
$ice-blue: #f7faff;
$little-light-black: #0000001a;
$light-skyblue: #e3edf1;
$paleBlueGrya: #cfd4da;
$fainted: #e0e5ec;
$fade-black: #00000080;

// Font size
$xxl: 32px;
$xl: 24px;
$l: 16px;
$color-box-height: 30px;
$color-box-width: 30px;

// Font family
// $default: aktiv-grotesk-extended, sans-serif;
$default: "Plus Jakarta Sans", "Noto Sans", sans-serif;
